import React from "react";
import { useParams } from "react-router";

import useWebSocket, { ReadyState } from "react-use-websocket";
import { usePosition } from "use-position";

import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import { WebsocketURL } from "../../utils/constants";

export const Driver = () => {
  const [successMsg, setSuccessMsg] = React.useState("");
  const [failureMsg, setFailureMsg] = React.useState("");
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = React.useState(false);
  const [isOpenFailureAlert, setIsOpenFailureAlert] = React.useState(false);

  const params = useParams();
  const { sendJsonMessage, readyState } = useWebSocket(WebsocketURL);
  const { latitude, longitude, accuracy, error: positionError } = usePosition();

  const onCloseSuccessAlert = React.useCallback(() => {
    setIsOpenSuccessAlert(false);
  }, []);

  const onCloseFailureAlert = React.useCallback(() => {
    setIsOpenFailureAlert(false);
  }, []);

  React.useEffect(() => {
    if (latitude && longitude) {
      if (readyState === ReadyState.OPEN && params.code) {
        sendJsonMessage({
          data: {
            type: "driver",
            phone: params.code,
            position: { latitude, longitude, accuracy },
          },
          action: "message",
        });

        setSuccessMsg("Your location successfully submitted.");
        setIsOpenSuccessAlert(true);
      }
    }
  }, [accuracy, latitude, longitude, params.code, readyState, sendJsonMessage]);

  React.useEffect(() => {
    if (positionError) {
      setFailureMsg(positionError);
      setIsOpenFailureAlert(true);
    }
  }, [positionError]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenFailureAlert}
        onClose={onCloseFailureAlert}
        key="top-right-error"
      >
        <Alert onClose={onCloseFailureAlert} severity="error">
          {failureMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSuccessAlert}
        onClose={onCloseSuccessAlert}
        key="top-right-success"
      >
        <Alert onClose={onCloseSuccessAlert} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
    </>
  );
};
