import React from "react";

const INITIAL_STATE = {
  isLoading: false,
  isWsConnected: false,
  websocket: null,
};

const StateContext = React.createContext({});
const DispatchContext = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case "WEBSOCKET_CONNECT":
      return { ...state, isWsConnected: true };
    case "WEBSOCKET_DISCONNECT":
      return { ...state, isWsConnected: false };
    case "SET_WEBSOCKET":
      return { ...state, websocket: action.payload };
    default:
      return state;
  }
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export { StateContext, DispatchContext, ContextProvider };
