import React from "react";

import useWebSocket, { ReadyState } from "react-use-websocket";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { DriversTable } from "./DriversTable";

import { WebsocketURL } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  row: {
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  phoneNumber: {
    marginLeft: "auto",
    width: 240,
  },
  btn: {
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
  },
}));

export const Agent = () => {
  const [drivers, setDrivers] = React.useState([]);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const classes = useStyles();

  const { sendJsonMessage, lastMessage, readyState } =
    useWebSocket(WebsocketURL);

  const onChangePhoneNumber = React.useCallback((ev) => {
    setPhoneNumber(ev.target.value);
  }, []);

  const onGetDriverLocation = React.useCallback(() => {
    if (!phoneNumber || readyState !== ReadyState.OPEN) return;

    sendJsonMessage({
      data: {
        type: "agent",
        driver: phoneNumber,
      },
      action: "message",
    });

    // reset phone number field
    setPhoneNumber("");
  }, [phoneNumber, readyState, sendJsonMessage]);

  React.useEffect(() => {
    if (!lastMessage) return;

    const { driver, position } = JSON.parse(lastMessage.data);

    if (driver && position) {
      const { latitude, longitude, accuracy } = position;
      setDrivers((prev) => [
        ...prev,
        {
          driver,
          latitude,
          longitude,
          "lat-lng": `${latitude}, ${longitude}`,
          accuracy: `${(+accuracy).toFixed(2)} m`,
        },
      ]);
    }
  }, [lastMessage]);

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} className={classes.row}>
          <FormControl variant="outlined" className={classes.phoneNumber}>
            <InputLabel htmlFor="driver-phone-number">
              Enter driver's phone number
            </InputLabel>
            <Input
              id="driver-phone-number"
              value={phoneNumber}
              onChange={onChangePhoneNumber}
            />
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={onGetDriverLocation}
          >
            Get Driver's Location
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DriversTable drivers={drivers} />
        </Grid>
      </Grid>
    </>
  );
};
