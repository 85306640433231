import React from "react";

import { DataGrid } from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";

const columns = [
  { field: "id", headerName: "ID", width: 80, sortable: false },
  { field: "driver", headerName: "Driver", width: 200, sortable: false },
  { field: "latitude", headerName: "Latitude", width: 200, sortable: false },
  { field: "longitude", headerName: "Longitude", width: 200, sortable: false },
  {
    field: "lat-lng",
    headerName: "Latitude-Longitude",
    width: 200,
    sortable: false,
  },
  { field: "accuracy", headerName: "Accuracy", width: 200, sortable: false },
];

export const DriversTable = ({ drivers }) => {
  const [rows, setRows] = React.useState([]);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [failureMsg, setFailureMsg] = React.useState("");
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = React.useState(false);
  const [isOpenFailureAlert, setIsOpenFailureAlert] = React.useState(false);

  const onCloseSuccessAlert = React.useCallback(() => {
    setIsOpenSuccessAlert(false);
  }, []);

  const onCloseFailureAlert = React.useCallback(() => {
    setIsOpenFailureAlert(false);
  }, []);

  const onClickCell = React.useCallback(async (params) => {
    try {
      const { value, field } = params;

      await navigator.clipboard.writeText(value);

      setIsOpenSuccessAlert(true);
      setSuccessMsg(`${field} - ${value} copied.`);
    } catch (err) {
      setIsOpenFailureAlert(true);
      setFailureMsg(JSON.stringify(err));
    }
  }, []);

  React.useEffect(() => {
    const temp = drivers.map((driver, index) => ({ id: index, ...driver }));
    setRows(temp);
  }, [drivers]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSuccessAlert}
        autoHideDuration={6000}
        onClose={onCloseSuccessAlert}
        message={successMsg}
        key="success-snackbar"
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenFailureAlert}
        autoHideDuration={6000}
        onClose={onCloseFailureAlert}
        message={failureMsg}
        key="failure-snackbar"
      />
      <div style={{ height: "calc(100vh - 160px)", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={9}
          onCellClick={onClickCell}
        />
      </div>
    </>
  );
};
