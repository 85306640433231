import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Container from "@material-ui/core/Container";

import { ContextProvider } from "./contexts/Context";

import { Agent } from "./pages/Agent/Agent";
import { Driver } from "./pages/Driver/Driver";

import "./App.css";

function App() {
  return (
    <>
      <Container>
        <ContextProvider>
          <Router>
            <Switch>
              <Route path="/" exact component={Agent} />
              <Route path="/driver/:code" component={Driver} />
            </Switch>
          </Router>
        </ContextProvider>
      </Container>
    </>
  );
}

export default App;
